import React from 'react';
import useStoryblokGlobalFooter from "../useStoryblokGlobalFooter";
import {getResolvedStoryblokUrl} from "../../MegaDropDown/helpers";

import * as styles from './styles.module.css';

export default function MenuSecondary() {
    const menuItems = getMenuItems();
    if (!menuItems) return null;

    return <div className="footer__bottom">
        <div className="footer__inner">
            <div id="block-footer" className="footer__nav">
                <ul className={styles.ul}>
                    <Links menuItems={menuItems} />
                </ul>
            </div>
        </div>
    </div>;
}

const Links = ({menuItems}) => {
    return menuItems.map((menuItem, i) => (
        <li key={i} className="nav__menu-item--footer nav__menu-item">
            <a href={getResolvedStoryblokUrl(menuItem.link)}>{menuItem.label}</a>
        </li>
    ));
};

const getMenuItems = () => {
    const globalFooter = useStoryblokGlobalFooter();
    return globalFooter.menu_secondary?.filter(
        item => item.component === 'menu-link'
            && !!item.label
            && (!!item.link?.url || !!item.link?.cached_url)
    );
};
