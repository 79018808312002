import React from 'react';
import {getResolvedStoryblokUrl} from '../helpers';
import { topLink } from './style.module.css';

export default function Link({menuData}) {
    const url = getResolvedStoryblokUrl(menuData.link);

    return (
        <span>
            <a className={topLink} href={url}>{menuData.label}</a>
        </span>
    );
}
