import React from 'react';

export default function Languages() {
    return <div className="footer__languages">
        <div className="footer__inner">
            <a href="http://nanoporetech.net/" className="footer__social-flag cn" style={{color: 'white'}}>中文</a>
            <a href="http://nanoporetech.jp/" className="footer__social-flag jp" style={{color: 'white'}}>日本語</a>
            <a href="http://nanoporetech.com/" className="footer__social-flag gb" style={{color: 'white'}}>English</a>
        </div>
    </div>;
}
