import React from 'react';
import { NanoIcon, NanoSticker } from '@nanoporetech-digital/components-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import LeftNav from '../LeftNav';
import {checkbutton, mobileMenuToggle, chevron, openIcon, navigation, closeLabel, navigationMenu} from "./style.module.css";

export default function ResponsiveNav({categories, activeCategory = 0, cmsBlocks={}}) {
    return (
        <>
            <input id="menu-toggle" type="checkbox" className={checkbutton} />

            <NanoSticker>
                <div className={mobileMenuToggle}>
                    <div><FontAwesomeIcon icon={faChevronLeft} className={chevron} /></div>
                    <div>
                        <label htmlFor="menu-toggle">
                            <NanoIcon name="light/ellipsis-vertical" className={openIcon} />
                        </label>
                    </div>
                </div>
            </NanoSticker>

            <div className={navigationMenu}>
                <label htmlFor="menu-toggle" className={closeLabel}>
                    <NanoIcon name="light/xmark" color="primary" className="closeIcon" />
                </label>
                <LeftNav categories={categories} activeCategory={activeCategory} className={navigation} id="nav" />
            </div>
        </>
    );
}
