// if a product belongs to multiple categories, find the lowest one
export function getLowestCategory(productCategories)
{
    if (productCategories?.length)
    {
        const sorted = [...productCategories].sort((a, b) => b.level - a.level);
        return sorted[0];
    }

    return null;
}

export function buildTrail(category, globalHomeUrl, liveMode, isProductPage) {

    const trail = [
        {label: "HOME", url: globalHomeUrl},
        {label: "Store", url: "/"}
    ];

    const ignore = [
        'consumables',
        'services'
    ]

    let atLeastOneCrumbAdded = false;

    if (category?.breadcrumbs?.length) {
        const sorted = [...category.breadcrumbs].sort((a, b) => a.category_level - b.category_level);
        sorted.forEach(crumb => {
            if (!ignore.includes(crumb.category_url_key)) {
                trail.push({
                    label: crumb.category_name,
                    url: getUrl(crumb, liveMode)
                });
                atLeastOneCrumbAdded = true;
            }
        });
    }

    if (isProductPage && !atLeastOneCrumbAdded && category.url_key) {
        trail.push({
            label: category.name,
            url: getUrl({category_id: category.id, category_url_key: category.url_key}, liveMode)
        });
    }

    return trail;
}

function getUrl(crumb, liveMode) {
    return (liveMode)
        ? `/catalog/?id=${crumb.category_id}`
        : `/${crumb.category_url_key}.html`;
}
