import React from 'react';
import QualityIcons from "./QualityIcons";
import Socials from "./Socials";
import Logo from "./Logo";

export default function Extras() {
    return <div className="footer__inner">
        <div className="footer__extras">
            <Logo />
            <Socials />
            <QualityIcons />
        </div>
    </div>;
}
