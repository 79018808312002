import React from 'react';
import { NanoIcon } from '@nanoporetech-digital/components-react';
import { linkWrapper, blockLink } from './style.module.css';
import {getResolvedStoryblokUrl} from '../helpers';

export default function PromoLink({promoLink}) {
    const { title, body, link } = promoLink;
    const url = getResolvedStoryblokUrl(link);

    return (
        <div className={linkWrapper}>
            <a className={blockLink} href={url}>
                <div>{title}<br /><small>{body}</small></div>
                <div><NanoIcon name="light/chevron-right" role="img" aria-label="chevron right"/></div>
            </a>
        </div>
    );
}
