// autofills address object from form - key names must match form field names
export function autofill(address, form, prefix) {
    let addressChanged = false;
    Object.keys(address).forEach(fieldName => {
        const prefixedName = prefix + fieldName;
        const formValue = form[prefixedName]?.value ?? '';
        if (formValue.length && address[fieldName] != formValue) {
            address[fieldName] = form[prefixedName].value;
            addressChanged = true;
        }
    });
    return addressChanged;
}

export function validate(form, prefix, uiTelephone=false) {
    let valid = true;
    ['firstname', 'lastname', 'company', 'street', 'city', 'region', 'postcode'].forEach(fieldName => {
        const field = form[`${prefix}${fieldName}`];
        success(field);     // reset previous errors
        if (field?.value?.length && !field.value.trim().length) valid = false;
        if (!validateLanguage(field)) valid = false;
        if (!validatePoBox(field)) valid = false;
        if (!validateFieldLength(field)) valid = false;
    });

    if (!validateCountryCode(form[`${prefix}country_code`])) valid = false;
    if (!validateRegionId(form[`${prefix}region_id`])) valid = false;
    if (!uiTelephone && !validateTelephone(form[`${prefix}telephone`])) valid = false;
    if (!validatePostcode(form[`${prefix}postcode`], form[`${prefix}country_code`]?.value)) valid = false;

    return valid;
}

function validateRegionId(field) {
    if (!field) {
        // field not found in form, which should mean region text field has been populated instead
        return true;
    }

    return (field.value !== 'NONE')
        ? success(field)
        : failure(field, 'Please choose a state/province from the dropdown.');
}

function validateCountryCode(field) {
    if (!field?.value) return true;
    return (/^[A-Z][A-Z]$/.test(field.value))
        ? success(field)
        : failure(field, 'Please choose a country from the dropdown.');
}

function validateTelephone(field) {
    if (!field?.value) return true;
    return (/^\+?((\d{1,3}[-. ]?)?((\(\d{3}\))|\d{3}))?[-. ]?\d{3,4}[-. ]?\d{4,5}$/.test(field.value))
        ? success(field)
        : failure(field, 'Please enter a valid phone number.');
}

function validatePostcode(field, countryCode) {
    if (!field?.value) return true;

    let valid = false;
    let example = null;

    switch(countryCode) {
        case 'AU':
            valid = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/.test(field.value);
            example = '1234';
            break;
        case 'AT':
            valid = /^\d{4}$/.test(field.value);
            example = '1234';
            break;
        case 'BE':
            valid = /^[1-9]{1}[0-9]{3}$/.test(field.value);
            example = '1234';
            break;
        case 'CA':
            valid = /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/.test(field.value);
            example = 'A1A 1A1';
            break;
        case 'CY':
            valid = /^\d{4}$/.test(field.value);
            example = '1234';
            break;
        case 'CZ':
            valid = /^\d{3}\s?\d{2}$/.test(field.value);
            example = '12345 or 123 45';
            break;
        case 'DE':
            valid = /^((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))$/.test(field.value);
            example = '01234';
            break;
        case 'DK':
            valid = /^\d{4}$/.test(field.value);
            example = '1234';
            break;
        case 'ES':
            valid = /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/.test(field.value);
            example = '12345';
            break;
        case 'FI':
            valid = /^\d{5}$/.test(field.value);
            example = '12345';
            break;
        case 'FR':
            valid = /^\d{5}$/.test(field.value);
            example = '12345';
            break;
        case 'GB':
            valid = /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/.test(field.value);
            example = 'AB123AB or AB12 3AB';
            break;
        case 'GR':
            valid = /^\d{3}\s?\d{2}$/.test(field.value);
            example = '12345 or 123 45';
            break;
        case 'HR':
            valid = /^\d{5}$/.test(field.value);
            example = '12345';
            break;
        case 'HU':
            valid = /^\d{4}$/.test(field.value);
            example = '1234';
            break;
        case 'IE':
            valid = /^([AC-FHKNPRTV-Y]{1}[0-9A-Z]{2})[ ]?[0-9AC-FHKNPRTV-Y]{4}$/.test(field.value);
            example = 'A1B12AB or A1B 12AB';
            break;
        case 'IN':
            valid = /^\d{6}$/.test(field.value);
            example = '123456';
            break;
        case 'IT':
            valid = /^\d{5}$/.test(field.value);
            example = '12345';
            break;
        case 'NL':
            valid = /^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$/.test(field.value);
            example = '1234AB or 1234 AB';
            break;
        case 'LU':
            valid = /^\d{4}$/.test(field.value);
            example = '1234';
            break;
        case 'MO':
            valid = /^980\d{2}$/.test(field.value);
            example = '98012';
            break;
        case 'MT':
            valid = /^[A-Za-z]{3}\s?\d{4}$/.test(field.value);
            example = 'ABC1234 or ABC 1234'
            break;
        case 'PL':
            valid = /^\d{2}[- ]?\d{3}$/.test(field.value);
            example = '12345 or 12 345 or 12-345';
            break;
        case 'PT':
            valid = /^\d{4}[- ]?\d{3}$/.test(field.value);
            example = '1234567 or 1234 567 or 1234-567';
            break;
        case 'RO':
            valid = /^\d{6}$/.test(field.value);
            example = '123456';
            break;
        case 'SE':
            valid = /^\d{3}\s?\d{2}$/.test(field.value);
            example = '12345 or 123 45';
            break;
        case 'SI':
            valid = /^\d{4}$/.test(field.value);
            example = '1234';
            break;
        case 'SK':
            valid = /^\d{3}\s?\d{2}$/.test(field.value);
            example = '12345 or 123 45';
            break;
        case 'US':
            valid = /^\d{5}([\-]?\d{4})?$/.test(field.value);
            example = '12345 or 12345-6789 or 123456789';
            break;
        default:
            valid = true;
    }

    return (valid) ? true : failure(field, `Invalid postcode for ${countryCode}. Example: ${example}`);
}

function validatePoBox(field) {
    return (/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)?\b/i.test(field?.value))
        ? failure(field, 'Courier is unable to deliver to a PO BOX address, please provide an alternative.')
        : true;
}

function validateLanguage(field) {
    return (/([^\u0000-\u00FF]|\t|\|)/.test(field?.value))
        ? failure(field, 'Our logistics provider does not recognise some of these characters.')
        : true;
}

function success(field) {
    const input = field?.closest('nano-input, nano-select');
    if (input) input.showError('');
    return true;
}

function failure(field, message) {
    let input = field?.closest('nano-input, nano-select');
    if (input) input.showError(message);
    return false;
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).trim().toLowerCase());
}

export function validateEmailCsv(emailsCsv) {
    const emails = emailsCsv.split(',');
    for (let email of emails) {
        if (!validateEmail(email)) return false;
    }
    return true;
}

export function validateEmailCsvFormField(field) {
    const value = field?.value;

    if (value) {
        return (validateEmailCsv(value)) ? success(field) : failure(field, 'Please enter valid email addresses separated by commas');
    } else {
        return true;
    }
}

export function isMandatoryDataComplete(dataObject, mandatoryAttributes, inputFieldForErrorMessage) {
    for (let fieldName of mandatoryAttributes) {
        if (!dataObject[fieldName]?.length) {
            debugger;
            return failure(inputFieldForErrorMessage, "Address is required");
        }
    }
    return success(inputFieldForErrorMessage);
}

export function validateFieldLength(field) {
    const maxLength = field?.maxLength ?? 0;
    const value = field?.value ?? '';
    if (maxLength > 0  && value.length > maxLength) {
        return failure(field, 'Maximum length exceeded');
    }
    return true;
}
