import React from 'react';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import { useTranslation, Trans } from 'react-i18next';

export default function PromethionFilter(props) {
    const { t } = useTranslation();

    return (
    <>
    <div grid-states="xxl-col-span-8 xl-col-span-8 xxl-col-start-3 xl-col-start-3 s-col-start-1 m-col-start-1 m-col-span-10 s-col-span-10">
        <div className="promethionFilter-group">
            <span><Trans i18nKey="Select package type" />:</span>
            <div className="promethionFilter-inner">
            <div className="promethionFilter">
                <NanoCheckbox
                    class="promethionFilter"
                    label={t("Project Pack")}
                    checked={props.promethionType==='project-pack'}
                    name="promethion_type"
                    type="radio"
                    onNanoChange={(e) => props.setPromethionType('project-pack')}
                ></NanoCheckbox>
                 <p><Trans i18nKey="prm_filter_project_pack" /></p>
                </div>
                <div className="promethionFilter">
                <NanoCheckbox
                    class="promethionFilter"
                    label={t("CapEx")}
                    checked={props.promethionType==='capex'}
                    name="promethion_type"
                    type="radio"
                    onNanoChange={(e) => props.setPromethionType('capex')}
                ></NanoCheckbox>
                <p><Trans i18nKey="prm_filter_capex" /></p>
                 </div>
                </div>
        </div>
        </div>
        <style jsx>{`
        .promethionFilter-group {
            // width:100vw;
            display:flex;
            flex-direction:column;
            gap:2rem;

        }

        .promethionFilter-group span{
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 1.6px;
            color: #0c5a71;
        }

        .promethionFilter-group .promethionFilter{
            border-radius: 3px;
            border: solid 1px #ccdee3;
            padding: 1rem;
            --check-base-size: 12px;

            }

        p {
            margin: 8px 0 0;
            line-height: 1.8;
            font-size:12px;
       }
       .promethionFilter-inner{
           display:flex;
           gap:1rem;

       }


        `}</style>
    </>
    );
}
