import React from 'react';
import Select from 'react-select';
import orderBy from 'lodash/orderBy';
import head from 'lodash/head';
import find from 'lodash/find';
import clsx from 'clsx';
import {pluralise} from '../Checkout/Sections/helpers';

import {customStyles} from './packs';
import UiSelect from "../Ui/UiSelect";
import {getWebsiteCode, TogglePrice} from "../../hooks/siteData";
import Price from "../Ui/Price";
import {t} from "i18next";

function VariantLabel({variant, selected, getTierPrice}) {
    const {size, product} = variant;
    const priceObject = product.ont_custom_price ?? product.price.regularPrice.amount;
    const { value: totalPrice, currency } = priceObject;
    const numberOfPacks = parseInt(size.label);
    const packSize = product.sku.includes('FLO-PRO') ? 4 : 1;
    const numberOfCells = numberOfPacks * packSize;
    const isFlowcell = product.ont_render_type === 'FLOWCELL' && product.sku.includes('FLO-');
    const variantTitle = pluralise(size.label, (packSize > 1 || !isFlowcell) ? 'Pack' : 'Flow cell');
    const useTotalPrice = getTierPrice(totalPrice, numberOfPacks);

    const PriceEach = () => {
        if (!isFlowcell) return null;
        return <div className="right detail" style={{fontSize: "13px", textAlign: "right"}}><Price currencyCode={currency} value={useTotalPrice / numberOfCells} /><span> each</span></div>;
    };

    const NumberOfFlowcells = () => {
        if (!isFlowcell) return null;
        if (packSize <= 1) return <div className="left detail">&nbsp;</div>;
        return <div className="left detail">{pluralise(numberOfCells, 'Flow cell')}</div>;
    }

    return (
        <div className="label">
            <div className="left title">{variantTitle}</div>
            <TogglePrice sku={product.sku}>
                <div className="right title" style={{textAlign: "right"}}><strong><Price currencyCode={currency} value={useTotalPrice} /></strong></div>
                <NumberOfFlowcells />
                <PriceEach />
            </TogglePrice>
        </div>
    );
}


export default function VariantsSelect({
    id,
    active,
    packSize,
    setSelectedVariant,
    selectedVariant,
    selectedType,
    getTierPrice
}) {

    const variants = orderBy(
        active.variants
            .filter(({product}) => {
                // Only include variants that are associated to the current website
                for (const website of product.websites) {
                    if (website.code === getWebsiteCode()) {
                        return true;
                    }
                }

                return false;
            })
            .filter(({attributes}) =>
                selectedType.label === "1" || attributes[0].value_index === selectedType.value
            )
            .map(variant => ({
                ...variant,
                size: head(
                    packSize.values.filter(
                        ({value_index}) => {
                            const sizeAttribute = find(
                                variant.attributes,
                                {code: 'ont_pack_size'},
                            );
                            return sizeAttribute.value_index === value_index
                        }
                    ),
                ),
            })),
        ({size}) => parseInt(size.label),
    );

    return (
        <>
            <div className={clsx('select', 'variants')}>
                <div className="label">Pack size:</div>
                <UiSelect
                    id={id}
                    isSearchable={false}
                    formatOptionLabel={
                        (option, state) => {
                            const {selectValue} = state;
                            const value = selectValue.length > 0 ? selectValue[0].value : null;
                            return (
                                <VariantLabel
                                    variant={option.variant}
                                    selected={option.variant.product.id === value}
                                    getTierPrice={getTierPrice}
                                />
                            );
                        }
                    }
                    styles={customStyles}
                    placeholder=""
                    onChange={({variant}) => {
                        setSelectedVariant(variant);
                    }}
                    options={
                        variants.map(variant => ({
                            value: variant.product.id,
                            variant,
                        }))
                    }
                />
            </div>
            <style jsx>{`
                .select {
                    max-width: 320px;
                }
                .select :global(div[class*='control']) {
                    min-height: 3rem;
                }
                .variants :global(.label) {
                    display: grid;
                    grid-template-columns: 50% 50%;
                }
                .select .label {
                    color: #455556;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: bold;
                    height: 1.5rem;
                    letter-spacing: normal;
                    line-height: 1.5;
                    margin-bottom:.375rem;
                }
                .variants :global(div[class*='singleValue'] .right) {
                    display: none;
                }
                .variants :global(div[class*='singleValue'] .left.title) {
                    font-weight: normal;
                }
                .variants :global(div[class*='singleValue'] .left.detail) {
                    text-align: right;
                }
            `}</style>
        </>
    );
}
