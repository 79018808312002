import React from 'react';
import UiButton from '../../Ui/UiButton';
import {getCwsUrl, getResolvedStoryblokUrl} from '../helpers';

export default function Button({item}) {
    const url = getResolvedStoryblokUrl(item.link);

    return (
        <UiButton type="button" className="button button--primary button--keyline" onClick={() => location.href=url}>
            {item.link_label}
        </UiButton>
    );
}
