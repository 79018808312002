import React from 'react';
import twitterX from "../../assets/x-twitter.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons";

export default function Socials() {
    return <div className="footer__social">
        <div className="footer__social-icons">
            <a href="https://twitter.com/nanopore" target="_blank"
               className="footer__social-icon"><img src={twitterX} height="24"/></a>
            <a href="https://www.youtube.com/channel/UC5yMlYjHSgFfZ37LYq-dzig" target="_blank"
               className="footer__social-icon"><FontAwesomeIcon icon={faYoutube}/></a>
            <a href="https://www.facebook.com/Oxford-Nanopore-Technologies-251034380246/"
               target="_blank" className="footer__social-icon"><FontAwesomeIcon icon={faFacebookF}/></a>
            <a href="https://www.linkedin.com/company/oxford-nanopore-technologies"
               target="_blank" className="footer__social-icon"><FontAwesomeIcon icon={faLinkedinIn}/></a>
            <a href="https://www.instagram.com/oxfordnanopore"
               target="_blank" className="footer__social-icon"><FontAwesomeIcon icon={faInstagram}/></a>
        </div>
    </div>;
}
