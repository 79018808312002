import React from 'react';
import Languages from "./Languages";
import Legal from "./Legal";
import MenuPrimary from "./MenuPrimary";
import MenuSecondary from "./MenuSecondary";

const Footer = ({}) => {
    return (
        <footer className="footer">
            <Languages/>
            <MenuPrimary />
            <MenuSecondary />
            <Legal />
            <style jsx>{`
                footer {
                    z-index: initial;
                }
            `}</style>
        </footer>
    );
}

export default Footer;
