import React from 'react';
import iso27001 from "../../assets/iso-27001.svg";
import iso9001 from "../../assets/iso-9001.svg";
import bsiMark from "../../assets/bsi-mark-of-trust.svg";

export default function QualityIcons() {
    return <>
        <a className="footer__quality" href="http://nanoporetech.com/commercial_information#tabs-0=policies">
            <div className="footer__quality-item">
                <img src={iso27001} alt="ISO 27001"/>
            </div>
            <div className="footer__quality-item">
                <img src={iso9001} alt="ISO 9001"/>
            </div>
            <div className="footer__quality-item">
                <img src={bsiMark} alt="BSI mark of trust"/>
            </div>
        </a>
        <style jsx>{`
            .footer__quality-item {
                max-width: 7rem;
                -webkit-box-flex: 1;
                flex: 1;
                padding-right: 1rem;
            }
        `}</style>
    </>;
}
