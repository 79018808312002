import React from 'react';
import {getResolvedStoryblokUrl} from '../helpers';

export default function Link({item}) {
    const url = getResolvedStoryblokUrl(item.link);

    return (
        <a href={url}>
            {item.label}
            {item.sub_text && <small>{item.sub_text}</small>}
        </a>
    );
}
