import useSiteData from '../../hooks/siteData';

export function getMenuByTitle(menuPrimary, title) {
    return menuPrimary.find((menu) => {
        const fieldToCheck = menu.title ?? menu.label ?? null;
        return fieldToCheck ? (fieldToCheck === title) : false;
    });
}

export function getCwsUrl() {
    const { home } = useSiteData();
    return home.startsWith('https') ? home : 'https://nanoporetech.com';
}

export function getAbsoluteUrl(urlOrPath) {
    if (urlOrPath.startsWith('http')) return urlOrPath;

    let path = urlOrPath;
    if (!path.startsWith('/')) path = '/' + path;
    const baseUrl = getCwsUrl();
    return baseUrl + path;
}

export function getResolvedStoryblokUrl(storyblokLink) {
    if (storyblokLink.url) return getAbsoluteUrl(storyblokLink.url);
    if (storyblokLink.cached_url) return getAbsoluteUrl(storyblokLink.cached_url);

    return '#';
}
