import React from 'react';
import { NanoIcon } from '@nanoporetech-digital/components-react';
import { cardWrapper, titleLink, cardBody, ctaWrapper } from './style.module.css';
import {getResolvedStoryblokUrl} from '../helpers';

export default function PromoCard({promoCard}) {
    const { title, body, link, link_label, image } = promoCard;
    const url = getResolvedStoryblokUrl(link);
    const bgImage = image?.filename ? {backgroundImage: `url(${image.filename})`} : {};

    const TitleLink = () => (
        <a className={titleLink} href={url}>
            <div>{title}</div>
            <div><NanoIcon name="light/chevron-right" role="img" aria-label="chevron right"/></div>
        </a>
    );

    const CardBody = () => (
        <div className={cardBody}>
            <div>
                <NanoIcon name="light/calendar" role="img" aria-label="calendar"/>
                <span>&nbsp;&nbsp;{body}</span>
            </div>
        </div>
    );

    const Cta = () => (
        <div className={ctaWrapper}>
            <button type="button" className="button button--primary" onClick={() => window.location.href = url}>
                {link_label}
            </button>
        </div>
    );

    return (
        <div className={cardWrapper} style={bgImage}>
            {title && <TitleLink />}
            {body && <CardBody />}
            {link_label && <Cta />}
        </div>
    );
}
