import React from 'react';
import Extras from "../Extras";
import { shouldShowQrCodes } from "../../../hooks/siteData";
import nanoQr from "../../QrCode/Nanoporetech.png";
import CurrencySwitcher from "../../WebStore/CurrencySwitcher";
import useStoryblokGlobalFooter from "../useStoryblokGlobalFooter";
import {getAbsoluteUrl, getResolvedStoryblokUrl} from "../../MegaDropDown/helpers";

const FOOTER_STYLES = `
    footer {
        z-index: initial;
    }
    .footer__nav ul {
        text-align: center;
        font-size: 1rem;
    }
    .footer__nav ul li a {
        color: #fff;
        font-size: 0.75rem;
    }
    .footer__legal p {
        font-size: 10px;
        line-height: 16px;
    }
`;

export default function MenuPrimary() {
    const linkCollections = getLinksCollections();
    if (!linkCollections) return null;

    return (
        <div className="footer__links">
            <div className="footer__inner">
                {linkCollections.map((linkCollection, i) => (
                    <LinkCollection
                        key={i}
                        title={linkCollection.title}
                        linkItems={linkCollection.items}
                        isFirst={i === 0}
                    />
                ))}
            </div>
            <Extras />
        </div>
    );
}

const LinkCollection = ({ title, linkItems, isFirst }) => (
    <div className="footer__column">
        <h4 className="footer__title">{title}</h4>
        <nav>
            <ul>
                <LinkItems linkItems={linkItems} isFirst={isFirst} />
                {isFirst && renderQrCode()}
                {isFirst && <CurrencySwitcher />}
            </ul>
        </nav>
        <style jsx>{FOOTER_STYLES}</style>
    </div>
);

const LinkItems = ({ linkItems }) => {
        return linkItems
            .filter(
                item => !!item.label
                    && (!!item.link?.url || !!item.link?.cached_url)
            )
            .map((item, i) => (
                <li key={i} className="footer__item">
                    <a href={getResolvedStoryblokUrl(item.link)}>{item.label}</a>
                </li>
        ));

}

const renderQrCode = () => {
    if (!shouldShowQrCodes()) return null;
    return (
        <li className="footer__item">
            <img src={nanoQr} width="100" alt="Nano QR Code" />
            <br />
            WeChat: Nanoporetech
        </li>
    );
};

const getLinksCollections = () => {
    const globalFooter = useStoryblokGlobalFooter();
    return globalFooter.menu_primary?.filter(item => item.component === 'menu');
};

const getUrl = (item) => {
    if (item.link?.url) return getAbsoluteUrl(item.link.url);
    if (item.link?.cached_url) return getAbsoluteUrl(item.link.cached_url);
    return '#';
};
