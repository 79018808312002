import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generatePdf } from './pdf/helpers';

export default function PdfList({tables}) {
    return (
        <div>
            <a href={"#"} onClick={() => generatePdf(tables)}>
                <FontAwesomeIcon icon={faDownload} size={"3x"} color={"#0084a9"} onClick={() => generatePdf(tables)}/>
                Download PDF
            </a>
        </div>
    )
}
