import React from 'react';

export default function UiCheckbox(props) {

    return (
        <>
            <input type="checkbox"
                   id={props.id}
                   name={props.name}
                   className="form__checkbox-input"
                   checked={props.checked}
                   onChange={() => props.onChange(!props.checked)}/>

            <label htmlFor={props.id} className="form__checkbox-label">
                {props.children}
            </label>
            <style jsx>{`
                    height: 1.5rem;
                    width: 1.5rem;
                    min-height: 1.5rem;
                    min-width: 1.5rem;
                    vertical-align: bottom;
                }
            `}</style>
        </>
    );
}
