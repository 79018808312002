import { useStaticQuery, graphql } from 'gatsby';

export default function useStoryblokGlobalFooter() {
    const data = useStaticQuery(graphql`
query footer {
  customApi {
    story {
      content {
        global_footer {
          legal_info

          menu_primary {
            title
            component
            items {
              label
              link {
                url
                cached_url
                id
              }
            }
          }

          menu_secondary {
            component
            label
            link {
              url
              cached_url
            }
          }
        }
      }
    }
  }
}
    `);

    // return {};

    return data?.customApi?.story?.content?.global_footer[0] ?? {};
}
