import 'jspdf-autotable';
import OntLogo from './assets/ONT_logo_reverse.png';
import OntWheelIcon from './assets/ONT_Wheel_Icon_RGB.png';
import {legalText} from "./legal";
import {t} from "i18next";
import {notoSansLight} from './assets/NotoSans-Light';

export function generatePdf(tables) {
    import('jspdf').then(jspdf => {
        let doc = new jspdf.jsPDF('p', 'pt');
        doc.addFileToVFS('NotoSans-Light.ttf', notoSansLight);
        doc.addFont('NotoSans-Light.ttf', 'NotoSans', 'normal')
        doc.setFont('NotoSans', 'normal');

        const defaultFontSize = doc.getFontSize();
        doc.setFillColor(1,124,166);
        doc.rect(0,0,doc.internal.pageSize.width,50, 'F');

        doc.addImage(OntLogo,
            'PNG',
            doc.internal.pageSize.width -180,
            10,
            150,
            27
        );

        doc.setFontSize(18);
        doc.setTextColor(255,255,255)
        doc.text("Price List", 40, 20);
        doc.setFontSize(10)
        doc.text(getDate(), 40, 35);
        doc.setFontSize(defaultFontSize);

        const columns = ['Product name', 'SKU', 'Description', t('List price')];
        let finalY = 60;
        const shownHeaders = [];

        for (const table of tables) {
            const rows = table.data.map(r => {
                return [r.name, r.sku, r.raw_description, r.raw_price]
            })

            if (finalY > 500) {
                doc.addPage()
                finalY = 20;
            }
            doc.autoTable(columns, rows, {
                startY: finalY + 60,

                showHead: 'firstPage',

                styles: {
                    font: 'NotoSans',
                    fontSize: 9,
                },

                headStyles: {
                    fillColor: [1, 124, 166],
                },

                columnStyles: {
                    0: {cellWidth: 120},
                    1: {cellWidth: 100},
                    2: {cellWidth: 215},
                    3: {cellWidth: 80},
                },

                rowPageBreak: 'avoid',

                didDrawPage: function(data) {
                    if (!shownHeaders.includes(table.category)) {
                        doc.setFontSize(20)
                        doc.setTextColor(40)
                        doc.text(table.category, data.settings.margin.left, finalY + 40)
                        shownHeaders.push(table.category)
                    }
                }
            });

            finalY = doc.previousAutoTable.finalY;
        }

        printFooters(doc);
        printFinalPage(doc);

        doc.save('ont_pricelist.pdf');
    })
}

function getDate() {
    const d = new Date();
    const month = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September",
        "October", "November", "December"
    ];
    return month[d.getMonth()] + ' ' + d.getFullYear();
}

function printFooters(doc) {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFontSize(8)
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.setFillColor(1,124,166);
        doc.line(
            40,
            doc.internal.pageSize.height -30,
            doc.internal.pageSize.width -40,
            doc.internal.pageSize.height -30,
            'F'
        )
        doc.setFillColor(88,177,172);
        doc.rect(
            doc.internal.pageSize.width -170,
            doc.internal.pageSize.height -30,
            130,
            20,
            'F'
        )
        doc.setTextColor(255,255,255)
        doc.setFontSize(10)
        doc.textWithLink(
            'store.nanoporetech.com',
            doc.internal.pageSize.width -160, doc.internal.pageSize.height - 18,
            {
                url: 'https://store.nanoporetech.com/'
            }
        )
    }
}

function printFinalPage(doc) {
    doc.addPage();
    doc.setFontSize(12)
    doc.setTextColor(102,102,102)
    doc.setFont('NotoSans', 'normal');

    doc.text("Oxford Nanopore Technologies plc", 40, 80);
    doc.textWithLink("+44 (0)845 034 7900", 40, 100, {url: "tel:+44 845 034 7900"});

    doc.text("Email:", 40, 140);
    doc.textWithLink("sales@nanoporetech.com", 75, 140, {url: "mailto:sales@nanoporetech.com"});
    doc.text("X:", 40, 160);
    doc.textWithLink("@nanopore", 52, 160, {url: "https://www.twitter.com/nanopore"});
    doc.text("LinkedIn:", 40, 180);
    doc.textWithLink("oxford-nanopore-technologies", 92, 180, {url: "https://www.linkedin.com/company/oxford-nanopore-technologies"});
    doc.text("Bluesky:", 40, 200);
    doc.textWithLink("nanoporetech.com", 88, 200, {url: "https://bsky.app/profile/nanoporetech.com"});
    doc.setTextColor(2, 132, 169);
    doc.textWithLink("www.nanoporetech.com", 40, 220, {url: "https://www.nanoporetech.com/"});

    console.log("pagewidth", doc.internal.pageSize.width);

    const imageWidth = 150;
    const imageX = (doc.internal.pageSize.width / 2) - (imageWidth / 2);

    doc.addImage(
        OntWheelIcon,
        'PNG',
        imageX,
        doc.internal.pageSize.height -500,
        imageWidth,
        imageWidth
    );

    doc.setFillColor(102,102,102);
    doc.line(
        40,
        doc.internal.pageSize.height -120,
        doc.internal.pageSize.width -40,
        doc.internal.pageSize.height -120,
        'S'
    )

    doc.setFontSize(7);
    doc.setTextColor(102,102,102)
    doc.setFont('NotoSans', 'normal');
    const split = doc.splitTextToSize(legalText, doc.internal.pageSize.width - 100);
    doc.text(split, 40, doc.internal.pageSize.height - 100);
}
