import React from 'react';
import {isChinaStore} from "../../../hooks/siteData";
import useStoryblokGlobalFooter from "../useStoryblokGlobalFooter";
import UiMarkdown from "../../Ui/UiMarkdown";

import { footerLegal } from './style.module.css';

export default function Legal() {
    const legalInfo = getLegalInfo();
    if (!legalInfo) return null;

    return <div className="footer__legal">
        <div className="footer__inner">
            <div id="block-globalfooterlegal" className="block block-block-content">
                <div className={footerLegal}>
                    <UiMarkdown>{legalInfo}</UiMarkdown>
                </div>
                {isChinaStore() && <p>Shanghai ICP number 沪ICP备20017565号</p>}
            </div>
        </div>
    </div>;
}

const getLegalInfo = () => {
    const globalFooter = useStoryblokGlobalFooter();
    return globalFooter.legal_info;
}
