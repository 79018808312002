import React, { useState } from 'react';
import { NanoAccordion, NanoDetails, NanoIcon } from '@nanoporetech-digital/components-react';
import {accordions, red, accordionWrapper, content, label, title} from './style.module.css';

export default function Accordions({messages}) {

    const relevantMessages = messages.filter(message => {
        return (message.type == 'accordion');
    });

    if (relevantMessages.length) {
        return (
            <div className={accordions}>
                {relevantMessages.map((message, i) => <Accordion key={i} message={message} />)}
            </div>
        );
    }

    return null;
}

function Accordion({message}) {
    const [isOpen, setIsOpen] = useState(false);

    const chevronText = isOpen ? 'Less info' : 'More info';

    const priority = 'red'; // in future this could be set by graphql within the message

    return (
        <div className={accordionWrapper}>
            <NanoAccordion className={red}>
                <NanoDetails className={red} onNanoOpened={() => setIsOpen(true)} onNanoClosed={() => setIsOpen(false)}>
                    <div slot="label" className={label}>
                        <div className={title}>
                            <div><NanoIcon name="light/circle-exclamation" size="large" /></div>
                            <div>&nbsp;{message.title}</div>
                        </div>
                        <div>{chevronText}</div>
                    </div>
                    <NanoIcon slot="icon-end" name="light/chevron-right" size="small" />
                    <div className={content + ' ' + red} dangerouslySetInnerHTML={{ __html: message.content }}></div>
                </NanoDetails>
            </NanoAccordion>
        </div>
    );
}
