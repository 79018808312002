import React from 'react';
import {navigate} from "gatsby";
import { useMutation, gql } from "@apollo/client";
import { useWebStoreContext } from "../contexts/WebStoreContext";
import { useCustomerContext } from "../contexts/CustomerContext";
import useLocalStorage from "../utils/useLocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useLazyCustomerCart, useMergeCarts } from '../hooks/useCart';
import { useCartContext } from '../contexts/CartContext';
import { isDistributorRootStore } from '../hooks/siteData';
import { getSessionExpiry } from '../components/Authentication/helpers';
import {resetCredentials} from "../components/Authentication/hooks/useLogin";

export default function AdminAuthenticatePage({location}) {
    const { webstore, setWebstore } = useWebStoreContext();
    const { customer, setCustomer, resetCustomer } = useCustomerContext();
    const { cart, updateCart, resetCart } = useCartContext();
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code') ?? null;
    const [authDestPath] = useLocalStorage('ont_auth_dest_path');
    const [customerTokenMutation, {called: customerTokenMutationCalled, error: customerTokenError}] = useAdminToken(code);
    const [customerCartQuery, {called: customerCartQueryCalled, data: customerCartData, error: customerCartError}] = useLazyCustomerCart();
    const [mergeCartsMutation, {error: mergeCartsError}] = useMergeCarts();

    if (typeof window==='undefined') return null;

    resetCredentials(!customerTokenMutationCalled, true)

    if (!customerTokenMutationCalled) {
        customerTokenMutation().then(data => {
            const tokenData = data.data.generateCustomerTokenFromAdminToken;
            const designatedStore = tokenData.intended_store_view;
            setCustomer({
                token: tokenData.token,
                has_extra_permission: tokenData.has_extra_permission,
                has_custom_pricing: tokenData.has_custom_pricing,
                intended_store_view: designatedStore,
                email: tokenData.email,
                expiry: getSessionExpiry(tokenData.session_duration),
                is_admin_mode: true,
                admin_mode: {
                    admin_token: code,
                    initial_customer_email: tokenData.initial_customer_email,
                    csc_username: tokenData.csc_username,
                }
            });
            setWebstore({...webstore, designatedStore: designatedStore});
        }).catch(error => {
            // do nothing we will catch this with customerTokenError
        });
    }

    if (customerTokenMutationCalled && customer.token && !customerCartQueryCalled) {
        customerCartQuery();
    }

    if (customerCartError || mergeCartsError) {
        return (
            <div style={{margin: '4rem'}}>
                <h2 style={{fontWeight: 'lighter'}}>Sorry, something went wrong...</h2>
                <p>An error occured during login. If you have trouble adding items to your cart, please contact Customer Services with the information below.</p>
                <code>{customerCartError?.message}</code><br />
                {customerCartError?.graphQLErrors?.length && <code>{customerCartError?.graphQLErrors[0].debugMessage}</code>}<br />
                <code>{mergeCartsError?.message}</code><br />
                <code>{mergeCartsError?.graphQLErrors[0].debugMessage}</code>
            </div>
        );
    }

    // we have a logged in customer and their cart
    if (customerCartQueryCalled && customer.token && customerCartData) {
        const customerCartId = customerCartData.customerCart.id;
        const guestCartId = cart.id?.length ? cart.id : null;
        if (guestCartId && guestCartId !== customerCartId) {
            mergeCartsMutation({variables: {guestCartId: guestCartId, customerCartId: customerCartId}})
                .then(data => updateCartAndRedirect(updateCart, data.data.mergeCarts, authDestPath))
                .catch(error => updateCartAndRedirect(updateCart, customerCartData.customerCart, authDestPath));
        } else {
            updateCartAndRedirect(updateCart, customerCartData.customerCart, authDestPath)
        }
    }

    if (customerTokenError?.graphQLErrors?.length) {
        return (
            <div style={{margin: '4rem'}}>
                <h2 style={{fontWeight: 'lighter'}}>Sorry, we could not log you in...</h2>
                <p>We have been unable to log you in to the store. This may be because you do not have a purchasing account.</p>
                <p>To register for an account please <a href="https://myaccount.nanoporetech.com/registration/apply">click here</a>.</p>
                <p>If you have a purchasing account and are still seeing this error, please contact Customer Services quoting the information below.</p>
                <code>{customerTokenError?.message}</code><br />
                <code>{customerTokenError?.graphQLErrors[0].debugMessage}</code>
            </div>
        );
    }

    return (
        <center>
            <h2 style={{textAlign: 'center', fontWeight: 'lighter'}}>Logging you in...</h2>
            <p><FontAwesomeIcon icon={faSpinner} size="lg" spin /></p>
        </center>
    );
}

function updateCartAndRedirect(updateCart, newCart, authDestPath) {
    updateCart(newCart);
    navigateOrRedirect(authDestPath);
}

// when we land on /authenticate we don't have a subfolder in the URL
// if authentication leads to a subfolder store the WebStore component will do a redirect to enforce store change
// but that does not apply to distributor store - so we need a hard redirect to dist/jp to force a store change
// function navigateOrRedirect(authDestPath) {
//     const to = authDestPath ?? '/';
//     if (isDistributorRootStore()) { // if redirecting we need a full URL
//         if (to.startsWith('/jp') || to.startsWith('/cn')) {
//             const {origin} = new URL(window.location.href);
//             console.log('authenticate - redirecting to:', origin + to);
//             window.location.replace(origin + to);
//             return;
//         }
//     }
//     console.log('authenticate - navigating to:', to);
//     navigate(to);
// }

function navigateOrRedirect(authDestPath) {
    navigate('/');
}

function useAdminToken(code) {
    return useMutation(gql`mutation {
        generateCustomerTokenFromAdminToken(
            adminToken: "${code}"
        ) {
            token
            has_extra_permission
	    has_custom_pricing
            intended_store_view
            email
            initial_customer_email
            csc_username
            session_duration
        }
    }`);
}
